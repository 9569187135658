import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

import { cookieName } from '@constants/loyaltyModal';
import { isFeatureEnabled } from '@utils';

export const useLoyaltyModal = (): boolean => {
  const { data: session } = useSession();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const router = useRouter();

  const [personalDetails, setPersonalDetails] =
    useState<PG.Response.Guest.TRootObject | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const isLoyaltyActive = isFeatureEnabled('isLoyaltyActive');

  useEffect(() => {
    if (session && session?.user?.email && isLoyaltyActive) {
      const { email } = session.user;

      const cookieKey = cookieName(email);

      const cookieExists = document?.cookie
        ?.split('; ')
        ?.some((row) => row.startsWith(cookieKey));

      if (!cookieExists) {
        document.cookie = `${cookieKey}=true;path=/;expires=Thu, 01 Jan 2030 12:00:00 UTC`;

        void fetch('/api/booking-domain/guest/')
          .then((res) => {
            if (res.status !== 200) {
              throw new Error('Invalid response');
            }
            return res.json();
          })
          .then((guest: PG.Response.Guest.TRootObject) => {
            setPersonalDetails(guest);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setIsLoading(false));
      }
    }
  }, [session, isLoyaltyActive]);

  useEffect(() => {
    if (
      !isLoading &&
      personalDetails &&
      personalDetails.email &&
      !personalDetails.loyaltyNumber
    ) {
      if (
        !router?.pathname.startsWith('/loyalty') &&
        !router?.pathname.includes('booking/personalia')
      ) {
        setShouldShowModal(true);
      }
    }
  }, [isLoading, personalDetails]);

  return shouldShowModal;
};
